import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { BreadCrumb, Button, GroupCard, Loader, TextInput, UnitAndGroupModal, FormStepArchiveButton } from 'components';
import { BreadCrumbData } from 'components/BreadCrumb';
import { useCreateUser, User, useUpdateUser, useDeleteUser, useResendLink } from 'graphql-api';
import { showToast, ToastType } from 'utils/toast';
import { AccessLevel } from 'redux/authentication/enums';
import { ReactComponent as Trash } from 'assets/trash-icon.svg';
import { ReactComponent as ArrowLeft } from 'assets/arrow-left-icon.svg';
import { ReactComponent as Edit } from 'assets/edit-icon.svg';
import { ReactComponent as PlusCircle } from 'assets/plus-circle-icon.svg';
import { ReactComponent as List } from 'assets/list-icon.svg';
import { ReactComponent as Mail } from 'assets/mail-icon.svg';
import { ReactComponent as Refresh } from 'assets/refresh-icon.svg';
import colors from 'utils/colors';
import { useAppSelector } from 'hooks/redux';

export interface TeacherStepProps {
  user?: User;
  breadcrumb: BreadCrumbData[];
  refetch?: () => void;
  role: AccessLevel;
}

export const TeacherStep: React.FC<TeacherStepProps> = ({ user, breadcrumb, refetch, role }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { accessLevel } = useAppSelector(({ auth }) => auth);
  const isSupport = accessLevel === AccessLevel.Support;
  const [changedUser, setChangedUser] = useState<User>();
  const [isEditSwitch, setIsEditSwitch] = useState(false);
  const [isUnitChangable, setIsUnitChangable] = useState(false);
  const [isUnitAndGroupModalOpen, setIsUnitAndGroupModalOpen] = useState(false);
  const [shouldSaveUnitAndGroups, setShouldSaveUnitAndGroups] = useState(false);

  useEffect(() => {
    setChangedUser(user);
  }, [user]);

  const [createUser, { loading: createLoading }] = useCreateUser({
    onCompleted(addUserData) {
      if (addUserData.addUser.slug) {
        refetch && refetch();
        navigate(`/users/${AccessLevel.Teacher}/${addUserData.addUser.slug}`);
        showToast({
          title: t('userMaintenanceScreen.user_added'),
          type: ToastType.SUCCESS,
        });
      }
    },
    onError() {
      showToast({
        title: t('userMaintenanceScreen.emailExists'),
        type: ToastType.ERROR,
      });
    },
  });

  const [updateUser, { loading: updateLoading }] = useUpdateUser({
    onCompleted(editUserData) {
      if (editUserData.editUser.role) {
        refetch && refetch();
        showToast({
          title: t('userMaintenanceScreen.user_edited'),
          type: ToastType.SUCCESS,
        });
      }
    },
    onError(e) {
      showToast({
        title: t(e.message),
        type: ToastType.ERROR,
      });
    },
  });

  const [deleteUser] = useDeleteUser({
    onCompleted(archiveUserData) {
      if (archiveUserData.archiveUser.role) {
        navigate('/users');
        showToast({
          title: t('userMaintenanceScreen.user_deleted'),
          type: ToastType.SUCCESS,
        });
      }
    },
    onError(e) {
      showToast({
        title: t(e.message),
        type: ToastType.ERROR,
      });
    },
  });

  const removeUser = () => {
    if (user) {
      deleteUser({ variables: { id: +user.id } });
    }
  };

  const addUser = () => {
    createUser({
      variables: {
        id: changedUser?.unit?.id,
        payload: {
          firstName: changedUser?.firstName,
          lastName: changedUser?.lastName,
          email: changedUser?.email,
          groupIds: changedUser?.groups?.map((item) => +item.id),
          unitIds: [changedUser?.unit?.id],
          role,
        },
      },
    });
  };

  const editUser = () => {
    updateUser({
      variables: {
        id: +(user as User).id,
        payload: {
          firstName: changedUser?.firstName,
          lastName: changedUser?.lastName,
          email: changedUser?.email,
          unitId: changedUser?.unit?.id,
          groupIds: changedUser?.groups?.map((item) => +item.id),
          role,
        },
      },
    });
  };

  const onChange = (key: keyof User, value: string) => {
    const changedObject = changedUser || ({} as User);
    setChangedUser({
      ...changedObject,
      [key]: value,
    });
  };

  const onEditList = () => {
    setIsEditSwitch((prevState) => !prevState);
  };

  const [resendEmailByUserId] = useResendLink({
    onCompleted(resendEmailByUserIdData) {
      if (resendEmailByUserIdData.resendEmailByUserId.message) {
        showToast({
          title: t('userMaintenanceScreen.email_sent'),
          type: ToastType.SUCCESS,
        });
      }
    },
    onError() {
      showToast({
        title: t('common.try_again_later'),
        type: ToastType.ERROR,
      });
    },
  });

  const removeGroupFromParent = (groupId: string) => {
    const changedObject = changedUser || ({} as User);
    const filterGroups = changedUser?.groups?.filter((item) => item.id !== groupId);
    if (filterGroups) {
      updateUser({
        variables: {
          id: +(user as User).id,
          payload: {
            firstName: changedUser?.firstName,
            lastName: changedUser?.lastName,
            email: changedUser?.email,
            unitId: changedUser?.unit?.id,
            groupIds: filterGroups?.map((item) => +item.id),
            role,
          },
        },
      });
      setChangedUser({
        ...changedObject,
        groups: filterGroups,
      });
    } else {
      setChangedUser({
        ...changedObject,
        groups: undefined,
        unit: undefined,
      });
    }
  };

  const onGroupSave = (newGroupUser: User) => {
    const changedObject = changedUser || ({} as User);
    const renderGroups = () => {
      if (!newGroupUser.groups) return [];
      const newGroup = newGroupUser.groups[0];
      if (changedObject.groups && newGroupUser?.unit?.id === changedUser?.unit?.id) {
        return changedObject.groups.concat(newGroup);
      }
      return [newGroup];
    };
    setChangedUser({
      ...changedObject,
      groups: renderGroups(),
      unit: newGroupUser.unit,
    });
    setIsUnitChangable(false);

    if (user?.id) {
      setShouldSaveUnitAndGroups(true);
    }
  };

  const onRemoveUnit = () => {
    const changedObject = changedUser || ({} as User);
    setChangedUser({
      ...changedObject,
      groups: undefined,
      unit: undefined,
    });
  };

  const onChangeUnit = () => {
    setIsUnitChangable(true);
    setIsUnitAndGroupModalOpen(true);
  };

  const onChangeGroup = () => {
    setIsUnitChangable(false);
    setIsUnitAndGroupModalOpen(true);
  };

  useEffect(() => {
    if (shouldSaveUnitAndGroups) {
      updateUser({
        variables: {
          id: +(user as User).id,
          payload: {
            unitId: changedUser?.unit?.id,
            groupIds: changedUser?.groups?.map(({ id }) => +id),
          },
        },
      });

      setShouldSaveUnitAndGroups(false);
    }
  }, [shouldSaveUnitAndGroups, updateUser, changedUser, user]);

  const disableSaveChanges = !changedUser?.firstName || !changedUser?.lastName || !changedUser?.email || !changedUser.groups || !changedUser?.groups.length;

  return (
    <>
      <div className="flex items-center justify-between relative mb-4">
        <ArrowLeft className="absolute md:-left-10 cursor-pointer" onClick={() => navigate(-1)} />
        <div className="text-teacher-blue text-xl md:text-3xl font-bold ml-10 md:ml-0">
          {!user ? t('userScreen.addNew.Teacher') : `${user.firstName} ${user.lastName}`}
        </div>
        {user && !isSupport && <FormStepArchiveButton onConfirm={removeUser} label={t('userScreen.deleteUser')} />}
      </div>
      <BreadCrumb data={breadcrumb} className="mb-4 md:mb-8" />
      <div className="md:grid md:grid-cols-2 md:gap-24">
        <div className="flex flex-col mb-6 md:mb-0">
          <div className="text-teacher-blue text-xl font-bold mb-2">{t('userScreen.teacherInfo')}</div>
          <TextInput
            label={t('userScreen.firstName')}
            value={changedUser?.firstName}
            onChange={(e) => onChange('firstName', e.target.value)}
            containerClassName="mb-4"
            disabled={isSupport}
          />
          <TextInput
            label={t('userScreen.lastName')}
            value={changedUser?.lastName}
            onChange={(e) => onChange('lastName', e.target.value)}
            containerClassName="mb-4"
            disabled={isSupport}
          />
          <TextInput
            label={t('userScreen.email')}
            value={changedUser?.email}
            onChange={(e) => onChange('email', e.target.value)}
            containerClassName="mb-2"
            disabled={isSupport}
          />
          {user && !isSupport && (
            <Button
              variant="tertiary"
              className="w-fit"
              labelClassName="w-full flex justify-center"
              onClick={() => resendEmailByUserId({ variables: { id: user ? +user?.id : undefined } })}
            >
              <Mail className="mr-2" />
              {t('userScreen.resendLink')}
            </Button>
          )}
          <div className="flex items-center justify-between mt-6 gap-6">
            <Button variant="secondary" onClick={() => navigate(-1)}>
              {t('common.cancel')}
            </Button>
            <Button
              variant="primary"
              onClick={() => (user ? editUser() : addUser())}
              icon={<Loader isLoading={updateLoading || createLoading} isSmall className="mr-2" color="white" />}
              disabled={disableSaveChanges || isSupport}
            >
              {t('common.saveChanges')}
            </Button>
          </div>
        </div>
        <div>
          <div className="flex items-center justify-between mb-4">
            <div className="text-teacher-blue text-xl font-bold">{t('userScreen.teachersUnitAndGroups')}</div>
            <Button
              variant="tertiary"
              onClick={onEditList}
              labelClassName="font-bold flex items-center !text-teacher-blue"
              className="w-fit !p-0"
              disabled={isSupport}
            >
              {!isEditSwitch && <List className="mr-2" />}
              {!isEditSwitch ? t('userScreen.editList') : t('common.cancel')}
            </Button>
          </div>
          {!!changedUser?.unit && (
            <div className="flex flex-col bg-white rounded-lg border-2 px-4 pt-4 pb-8 border-admin-blue">
              <div className="flex items-center justify-between mb-1">
                <div className="text-teacher-blue font-bold">{changedUser?.unit.name}</div>
                {isEditSwitch ? (
                  <Trash className="cursor-pointer ml-2" stroke={colors.teacherBlue} onClick={onRemoveUnit} />
                ) : (
                  <Edit className="cursor-pointer" onClick={() => navigate(`/units/${changedUser?.unit?.slug}`)} />
                )}
              </div>
              <div className="text-teacher-blue">{`${changedUser?.unit.zipCode}, ${changedUser?.unit.city}, ${changedUser?.unit.addressLine}`}</div>
            </div>
          )}
          {isEditSwitch && changedUser?.groups && (
            <div className="flex justify-center mt-3">
              <Button variant="tertiary" labelClassName="!text-teacher-blue w-full flex justify-center" className="w-fit" onClick={onChangeUnit}>
                <Refresh className="mr-2" />
                {t('userScreen.changeUnit')}
              </Button>
            </div>
          )}
          {!!changedUser?.unit && <div className="my-4 mx-auto w-3/4 h-0.5 bg-divider" />}
          {!!changedUser &&
            changedUser.groups &&
            changedUser.groups.map((item) => (
              <GroupCard
                key={item.id}
                group={item}
                numberOfChildren={item.children.length}
                onDelete={isEditSwitch ? () => removeGroupFromParent(item.id) : undefined}
                onEdit={!isEditSwitch ? () => navigate(`/units/${changedUser?.unit?.slug}/${item.slug}`) : undefined}
              />
            ))}
          <UnitAndGroupModal
            visible={isUnitAndGroupModalOpen}
            openModal={setIsUnitAndGroupModalOpen}
            onSave={onGroupSave}
            selectedGroups={changedUser?.groups ? changedUser?.groups.map((item) => item.id) : []}
            selectedUnitId={changedUser?.unit?.id}
            isChangeUnit={isUnitChangable}
          />
          {(isEditSwitch || !changedUser?.groups) && (
            <div className="flex justify-center">
              <Button variant="tertiary" labelClassName="!text-teacher-blue w-full flex justify-center" className="w-fit" onClick={onChangeGroup}>
                <PlusCircle className="mr-2" />
                {t('groupMaintenanceScreen.new')}
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
